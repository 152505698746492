import { useMutation } from "@apollo/client";
import {
    constantsData,
    identifiersData,
    namesData,
    statusTypesData,
} from "data";
import { downloadTracksContentMutation } from "graph";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openAlert } from "redux/actions";
import { displayErrorHandler } from "utils";

const useDownloadTracksContent = () => {
    const [exporting, setExporting] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [downloadTracksContentAction, { loading }] = useMutation(downloadTracksContentMutation);

    const {
        downloadTracksContentPending: downloadTracksContentPendingToastName,
    } = namesData.toasts;

    const { toast: toastId } = identifiersData.ids;

    const downloadTracksContent = async (ids) => {
        setExporting(true);

        try {
            if (ids.length <= window?.__RUNTIME_CONFIG__?.REACT_APP_TRACKS_DOWNLOAD_LIMIT ) {/* eslint-disable-line */
                await downloadTracksContentAction({
                    onCompleted: () => {
                        setExporting(false);
                        dispatch(openAlert(
                            t(
                                "messages.downloadMsgs.successAndLinkPending",
                                { entity: t("tracks.name") },
                            ),
                            statusTypesData.success,
                        ));
                    },
                    variables: { tracksIds: ids },
                });
            } else {
                dispatch(openAlert(
                    t(
                        "messages.downloadMsgs.entitiesLengthError",
                        { entity: t("tracks.name") },
                    ),
                    statusTypesData.error,
                ));
            }
        } catch (err) {
            displayErrorHandler(
                err.message === constantsData.failedToFetch ? t("messages.networkFailure") : err.message,
                dispatch,
                openAlert,
            );
        } finally {
            toast.dismiss(`${downloadTracksContentPendingToastName}_${toastId}`);
        }
    };

    useEffect(
        () => {
            if (loading && exporting) {
                toast.info(
                    () => t(
                        "messages.downloadMsgs.pending",
                        { entity: t("tracks.name") },
                    ),
                    { toastId: `${downloadTracksContentPendingToastName}_${toastId}` },
                );
            }
        },
        [loading, exporting], //eslint-disable-line
    );

    return {
        downloadTracksContent,
        loading,
    };
};

export default useDownloadTracksContent;
